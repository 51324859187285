import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout1 from './shared/layouts/Layout1'
import Layout1Flex from './shared/layouts/Layout1Flex'
import Layout2 from './shared/layouts/Layout2'
import Layout2Flex from './shared/layouts/Layout2Flex'
import LayoutHorizontalSidenav from './shared/layouts/LayoutHorizontalSidenav'
import LayoutWithoutSidenav from './shared/layouts/LayoutWithoutSidenav'
import LayoutWithoutNavbar from './shared/layouts/LayoutWithoutNavbar'
import LayoutWithoutNavbarFlex from './shared/layouts/LayoutWithoutNavbarFlex'
import LayoutBlank from './shared/layouts/LayoutBlank'

// Lazy load component
const lazy = (cb) =>
  loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

// ---
// Default application layout

export const DefaultLayout = Layout2

// ---
// Document title template

export const titleTemplate = '%s - Business WIN!'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/login'
export const routes = [
  {
    path: '/login',
    component: lazy(() => import('./components/auth/Login')),
    layout: LayoutBlank
  },
  {
    path: '/projects',
    component: lazy(() => import('./components/projects/List'))
  }
]
